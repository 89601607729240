<template>
  <div>
    <v-snackbar
      v-model="copied"
      color="success"
      >
      Enlace copiado al portapapeles.
    </v-snackbar>

    <v-dialog
      content-class="custom-dialog rounded-b-0 rounded-t-xl"
      transition="dialog-bottom-transition"
      v-model="dialog"
      width="500"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="icon"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          fab
          depressed
          outlined
          x-small
          class="mt-n5"
          style="background-color: white"
          >
          <v-icon>
            mdi-share-variant
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          x-small
          outlined
          class="rounded-lg py-3 fill-width"
          v-bind="attrs"
          v-on="on"
          >
          <v-icon
            class="mr-1"
            color="secondary"
            small
            >
            mdi-share-variant
          </v-icon>
          Compartir
        </v-btn>
      </template>

      <v-card
        class="rounded-b-0 rounded-t-xl"
        >
        <v-card-title class="justify-center">
          Compartir perfil
        </v-card-title>

        <v-card-text
          class="px-0"
          >
          <v-btn
            text
            class="fill-width"
            color="primary"
            @click="copyUrl"
            v-if="currentPlatform"
            >
            <v-icon class="mr-2">mdi-content-copy</v-icon> Copiar enlace al perfil
          </v-btn>
          <v-btn
            text
            v-if="$vuetify.breakpoint.mobile"
            class="fill-width mt-3"
            color="primary"
            @click="shareUrl"
            >
            <v-icon class="mr-2">mdi-whatsapp</v-icon> Compartir {{ currentPlatform ? 'por WhatsApp' : '' }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Share } from '@capacitor/share';

export default {
  data: () => ({
    dialog: false,
    copied: false,
  }),

  props: {
    icon: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentPlatform () {
      return Capacitor.getPlatform() === 'web'
    }
  },

  methods: {
    copyUrl () {
      navigator.clipboard.writeText(window.location.href);

      this.copied = true
      this.dialog = false
    },

    async shareUrl () {
      var url = window.location.href

      if (Capacitor.getPlatform() === 'web') {
        // Tint statusbar color
        window.open(`whatsapp://send?text=¡Mira este perfil de PartnersFans! ${url}`)
      } else {
        await Share.share({
          title: 'Mira este perfil de PartnersFans',
          text: '¡Mira este perfil de PartnersFans!',
          url: 'https://www.partnersfans.cl' + window.location.pathname,
          dialogTitle: 'Comparte este perfil',
        });
      }
    }
  }
}
</script>
